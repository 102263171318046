import React from "react";
import { useState, useEffect, useRef } from "react";
import {
	getStorage,
	ref,
	getDownloadURL,
	uploadBytesResumable,
} from "firebase/storage";
import { set } from "firebase/database";

const ImageUpload = (props) => {
	const [file, setFile] = useState();
	const [isValid, setIsValid] = useState(false);
	const [previewUrl, setPreviewUrl] = useState();
	const [isUploaded, setIsUploaded] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);

	const filePickRef = useRef();

	const storage = getStorage();

	useEffect(() => {
		if (!file) {
			return;
		}

		const fileReader = new FileReader();
		fileReader.onload = () => {
			setPreviewUrl(fileReader.result);
		};
		fileReader.readAsDataURL(file);
	}, [file]);

	const pickHandler = (event) => {
		let pickedFile;
		if (event.target.files && event.target.files.length === 1) {
			pickedFile = event.target.files[0];
			setFile(pickedFile);
			setIsValid(true);
			setIsUploaded(false); // Reset isUploaded to false when a new file is picked
		} else {
			setIsValid(false);
		}
	};

	const pickImageHandler = () => {
		filePickRef.current.click();
	};

	const uploadImageHandler = async () => {
		setIsUploading(true);
		const dateTime = giveCurrentDateTime();

		const storageRef = ref(
			storage,
			`files/${file.name + "       " + dateTime}`
		);

		// Create file metadata including the content type
		const metadata = {
			contentType: file.type,
		};

		// Upload the file in the bucket storage
		const uploadTask = uploadBytesResumable(storageRef, file, metadata);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				// Handle progress
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setUploadProgress(progress);
				console.log("Upload is " + progress + "% done");
			},
			(error) => {
				// Handle error
				console.log("Error uploading file: ", error);
				setIsUploading(false);
			},
			() => {
				// Handle successful upload
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setIsUploaded(true);
					setIsUploading(false);
					console.log("File successfully uploaded.", downloadURL);
					props.onInput(props.id, downloadURL, isValid);
				});
			}
		);
	};

	const giveCurrentDateTime = () => {
		const today = new Date();
		const date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1) +
			"-" +
			today.getDate();
		const time =
			today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
		const dateTime = date + " " + time;
		return dateTime;
	};

	return (
		<div
			className="form-control"
			style={{ textAlign: "center", margin: "20px" }}
		>
			<input
				type="file"
				style={{ display: "none" }}
				id={props.id}
				accept=".jpg,.png,.jpeg"
				onChange={pickHandler}
				ref={filePickRef}
			/>
			<div
				className={`image-upload ${props.center && "center"}`}
				style={{ display: "inline-block", textAlign: "center" }}
			>
				<div className="image-upload__preview" style={{ marginBottom: "10px" }}>
					{previewUrl && (
						<img
							src={previewUrl}
							alt="Preview"
							style={{ width: "100%", maxHeight: "300px", objectFit: "cover" }}
						/>
					)}
					{!previewUrl && <p> Please pick an image.</p>}
				</div>
				<button
					type="button"
					onClick={pickImageHandler}
					style={{
						margin: "10px",
						padding: "10px 20px",
						backgroundColor: "#007bff",
						color: "white",
						border: "none",
						borderRadius: "5px",
						cursor: "pointer",
					}}
				>
					PICK IMAGE
				</button>
				{previewUrl && !isUploaded && (
					<button
						onClick={uploadImageHandler}
						disabled={isUploading}
						style={{
							margin: "10px",
							padding: "10px 20px",
							backgroundColor: isUploading ? "#6c757d" : "#28a745",
							color: "white",
							border: "none",
							borderRadius: "5px",
							cursor: isUploading ? "not-allowed" : "pointer",
						}}
					>
						UPLOAD
					</button>
				)}
				{isUploading && (
					<div style={{ width: "100%", marginTop: "10px" }}>
						<div
							style={{
								width: "100%",
								height: "10px",
								backgroundColor: "#e0e0e0",
								borderRadius: "5px",
								overflow: "hidden",
							}}
						>
							<div
								style={{
									width: `${uploadProgress}%`,
									height: "100%",
									backgroundColor: "#76c7c0",
									transition: "width 0.5s ease-in-out",
								}}
							></div>
						</div>
						<p>{Math.round(uploadProgress)}% uploaded</p>
					</div>
				)}
				{isUploaded && (
					<p style={{ color: "green" }}>Image uploaded successfully!</p>
				)}
				{!isValid && <p style={{ color: "red" }}>{props.errorText}</p>}
			</div>
		</div>
	);
};

export default ImageUpload;
